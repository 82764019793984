import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function refreshPage() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.history.scrollRestoration = "manual";
    //
    //window.scrollTo(0, 0);
    //navigate(0);
    //window.scrollTo(0, 0);
    //window.location.reload();
  }

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    return refreshPage;
  }, [pathname]);
};

export default ScrollToTop;
