import React, { Suspense, lazy, useEffect, useState, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  Link,
  useMatch,
  useResolvedPath,
  useLocation,
  useParams,
} from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

const Head = lazy(() => import("./components/header/header"));
const Head_Ar = lazy(() => import("./ar_components/header/header"));
const Home = lazy(() => import("./pages/home/home"));
const Home_Ar = lazy(() => import("./ar_pages/home/home"));
const Company = lazy(() => import("./pages/page/company"));
const Company_Ar = lazy(() => import("./ar_pages/page/company"));
const Media = lazy(() => import("./pages/page/media"));
const Media_Ar = lazy(() => import("./ar_pages/page/media"));

const PressReleaseSingle = lazy(() =>
  import("./pages/page/pressReleaseSingle")
);
const PressReleaseSingle_Ar = lazy(() =>
  import("./ar_pages/page/pressReleaseSingle")
);
const GallerySingle = lazy(() => import("./pages/page/GallerySingle"));
const GallerySingle_Ar = lazy(() => import("./ar_pages/page/GallerySingle"));
const Careers = lazy(() => import("./pages/page/careers"));
const Careers_Ar = lazy(() => import("./ar_pages/page/careers"));
const Careersapply = lazy(() => import("./pages/page/careersApply"));
const Careersapply_Ar = lazy(() => import("./ar_pages/page/careersApply"));
const Contactus = lazy(() => import("./pages/page/contact"));
const Contactus_Ar = lazy(() => import("./ar_pages/page/contact"));
const SolutionsServices = lazy(() => import("./pages/page/solutions_services"));
const SolutionsServices_Ar = lazy(() =>
  import("./ar_pages/page/solutions_services")
);
const SolutionsServicesSingle = lazy(() =>
  import("./pages/page/solutions_services_single")
);
const SolutionsServicesSingle_Ar = lazy(() =>
  import("./ar_pages/page/solutions_services_single")
);
const Footer = lazy(() => import("./components/footer/footer"));
const Footer_Ar = lazy(() => import("./ar_components/footer/footer"));
AOS.init();

function App() {
  const location = useLocation();

  return (
    <>
      {location.pathname.split("/")[1] === "en" ? <Head /> : <Head_Ar />}
      <main>
        <Suspense fallback={<div className="loading loading-lg"></div>}>
          <Routes forceRefresh={true}>
            <Route path="/" element={<Navigate to="/en/" />} />
            <Route path="/en/" element={<Home />} />
            <Route path="/ar/" element={<Home_Ar />} />
            <Route path="/en/company" element={<Company />} />
            <Route path="/ar/company" element={<Company_Ar />} />
            <Route
              path="/en/media/gallery"
              element={<Navigate to="/en/media" />}
            />
            <Route
              path="/ar/media/gallery"
              element={<Navigate to="/ar/media" />}
            />
            <Route
              path="/en/media/press-release"
              element={<Navigate to="/en/media" />}
            />
            <Route
              path="/ar/media/press-release"
              element={<Navigate to="/ar/media" />}
            />
            <Route path="/en/media" element={<Media />} />
            <Route path="/ar/media" element={<Media_Ar />} />
            <Route path="/en/careers" element={<Careers />} />
            <Route path="/ar/careers" element={<Careers_Ar />} />
            <Route path="/en/careers/:slug" element={<Careersapply />} />
            <Route path="/ar/careers/:slug" element={<Careersapply_Ar />} />
            <Route path="/en/contact-us" element={<Contactus />} /> {/*  */}
            <Route path="/ar/contact-us" element={<Contactus_Ar />} />
            <Route
              path="/en/solutions-&-services/"
              element={
                <Navigate to="/en/solutions-&-services/it-infrastructure-solutions" />
              }
            />
            <Route
              path="/ar/solutions-&-services/"
              element={
                <Navigate to="/ar/solutions-&-services/it-infrastructure-solutions" />
              }
            />
            <Route
              path="/en/solutions-&-services/:main"
              element={<SolutionsServices />}
            />
            <Route
              path="/ar/solutions-&-services/:main"
              element={<SolutionsServices_Ar />}
            />
            <Route
              path="/en/solutions-&-services/:main/:page"
              element={<SolutionsServicesSingle />}
            />
            <Route
              path="/ar/solutions-&-services/:main/:page"
              element={<SolutionsServicesSingle_Ar />}
            />
            {/*  */}
            <Route
              path="/en/media/press-release/:slug"
              element={<PressReleaseSingle />}
            />
            <Route
              path="/ar/media/press-release/:slug"
              element={<PressReleaseSingle_Ar />}
            />
            <Route path="/en/media/gallery/:slug" element={<GallerySingle />} />
            <Route
              path="/ar/media/gallery/:slug"
              element={<GallerySingle_Ar />}
            />
          </Routes>
        </Suspense>
      </main>
      {location.pathname.split("/")[1] === "en" ? <Footer /> : <Footer_Ar />}
    </>
  );
}

export default App;

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
